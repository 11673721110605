const oraLimiteVistaOrdiniOdierni = 12;

const giornoOrdiniAttuale = () => {
	const adesso = new Date;
	if( adesso.getHours() < oraLimiteVistaOrdiniOdierni ) {
		adesso.setDate(adesso.getDate() - 1);
	}
	return new Intl.DateTimeFormat('it', { dateStyle: 'full' }).format(adesso);
}

export { giornoOrdiniAttuale }