
import { computed, defineComponent, ref, watch } from 'vue';
import Login from '@/components/Login.vue'
import { useStore } from "@/store";
import { PullRefresh, Dialog } from "vant";
import { getOrdiniOdierni } from "@/services/api";
import RigaProdotto from "@/components/RigaProdotto.vue";
import { giornoOrdiniAttuale } from "@/helpers/ordini";

export default defineComponent({
	name: 'HomeView',
	components: {
		Login, PullRefresh, RigaProdotto
	},
	setup() {
		const store = useStore();
		const loading = ref(false);
		const chiusi = ref<boolean[]>([]);

		const prodotti = computed(() => Object.values(store.state.prodottiOdierni).sort((a, b) => a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1));
		const giorno = ref(giornoOrdiniAttuale());

		function fillChiusi(val: boolean) {
			chiusi.value = [...Array(prodotti.value.length)].fill(val);
		}

		fillChiusi(true);

		watch(prodotti, () => fillChiusi(true));

		const onRefresh = async () => {
			await getOrdiniOdierni();
			giorno.value = giornoOrdiniAttuale();
			loading.value = false;
		};

		const toggle = (index: number) => {
			chiusi.value[index] = !chiusi.value[index];
		}

		const apriTutti = ref(false);

		const toggleTutti = () => {
			const val = apriTutti.value;
			apriTutti.value = !val;
			fillChiusi(val);
		}

		watch(chiusi, (newVal) => {
			const el = newVal[0];
			// se tutti i valori di chiusi sono uguali,
			// imposta il valore di apriTutti
			if(newVal.every(x => x === el) && apriTutti.value === el) {
				apriTutti.value = !el;
			}
		}, { deep: true });

		const resetChecked = () => {
			Dialog.confirm({
				title: 'Reset prodotti',
				message: 'Sei sicuro di voler resettare le i prodotti contrassegnati?',
				cancelButtonText: 'Annulla',
				confirmButtonText: 'Conferma',
			})
			.then(() => store.commit('setProdottiChecked', []));
		}

		return {
			loggedIn: computed(() => !!store.state.credentials),
			loading, onRefresh, prodotti, chiusi, toggle,
			apriTutti, toggleTutti, giorno,
			resetChecked
		}
	}
});
