import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import Prodotti from "@/types/prodotti";

// define your typings for the store state
interface State {
	credentials: string,
	prodottiOdierni: Prodotti,
	prodottiChecked: number[]
}

// define injection key
const key: InjectionKey<Store<State>> = Symbol();

const store = createStore<State>({
	state: {
		credentials: '',
		prodottiOdierni: {},
		prodottiChecked: []
	},
	mutations: {
		initialiseStore(state) {
			// Check if the ID exists
			const json = localStorage.getItem('storeOrdini');
			if(json) {
				// Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(json))
				);
			}
			console.log('store initialized');
		},
		setCredentials(state, payload) {
			state.credentials = payload
			console.log('credentials set')
		},
		setProdottiOdierni(state, payload) {
			state.prodottiOdierni = payload
			console.log('prodottiOdierni set')
		},
		setProdottiChecked(state, payload) {
			state.prodottiChecked = payload
			console.log('prodottiChecked set')
		},
	},
	actions: {
		login(context, base64_credentials) {
			console.log('login action')
			context.commit('setCredentials', base64_credentials);
		},
		logout(context) {
			console.log('logout action')
			context.commit('setCredentials', '')
		},
		toggleCheckProdotto({ commit, state }, id_prod) {
			console.log(state.prodottiChecked);
			let prodottiChecked = [...state.prodottiChecked];
			if(prodottiChecked.includes(id_prod)) {
				prodottiChecked = prodottiChecked.filter(item => item !== id_prod);
			} else {
				prodottiChecked.push(id_prod);
			}
			commit('setProdottiChecked', prodottiChecked);
		}
	}
})

// Subscribe to store updates
store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('storeOrdini', JSON.stringify(state));
	console.log('store saved in localStorage');
});

// define your own `useStore` composition function
const useStore = () => {
	return baseUseStore(key)
}

// export the store
export { store, useStore, key }