
import { computed, defineComponent, PropType } from 'vue'
import Prodotto from "@/types/prodotto";
import { useStore } from "@/store";

export default defineComponent({
	props: {
		prodotto: {
			required: true,
			type: Object as PropType<Prodotto>
		},
		chiuso: Boolean
	},
	setup(props) {
		const store = useStore();
		const checked = computed(() => store.state.prodottiChecked.includes(props.prodotto.id));
		const classChecked = computed(() => checked.value ? 'text-gray-400' : '');
		const toggleChecked = () => store.dispatch('toggleCheckProdotto', props.prodotto.id)

		return { checked, classChecked, toggleChecked }
	},
})
