import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from '@/store';
import { Notify } from "vant";
import Ordine from "@/types/ordine";
import Prodotti from "@/types/prodotti";

const API = axios.create({
	baseURL: process.env.VUE_APP_API_URL
})

const login = async (user: string, pwd: string) => {
	try {
		const credentials = btoa(`${user}:${pwd}`);
		const res = await API.post('tool/login', {autorizza: credentials});
		if(res.data) {
			store.dispatch('login', credentials);
			return true;
		}
	} catch(err) {
		console.error(err);
	}
	return false;
}

function maybeLogout( err: any ) {
	if(err?.response?.status == 401) {
		store.dispatch('logout');
		Notify({ message: 'Utente non autorizzato', type: 'danger' })
	}
}

const getOrdiniOdierni = async () => {
	try {
		const res = await API.post('tool/ordiniOdierni', {autorizza: store.state.credentials});
		console.log(res.data);
		const prodotti = formattaProdotti(res.data);
		console.log(prodotti);
		store.commit('setProdottiOdierni', prodotti);
	} catch(err: any) {
		console.error(err);
		maybeLogout(err);
	}
}

function formattaProdotti( ordini: Ordine[] ) {
	const prodotti: Prodotti = {};
	for (const ordine of ordini) {
		const carrello = JSON.parse(ordine.carrello);
		const cliente = JSON.parse(ordine.cliente);
		for (const riga of carrello) {
			// console.log(riga);
			if(!prodotti.hasOwnProperty(riga.id)) {
				prodotti[riga.id] = { id: riga.id, nome: riga.nome, formato: riga.formato, qta: 0, clienti: {} };
			}
			prodotti[riga.id]['qta'] += riga.qta;
			if(!prodotti[riga.id]['clienti'].hasOwnProperty(cliente[0])) {
				prodotti[riga.id]['clienti'][cliente[0]] = { nome: cliente[1][0], qta: 0 };
			}
			prodotti[riga.id]['clienti'][cliente[0]]['qta'] += riga.qta;
		}
	}
	return prodotti;
}

export { login, getOrdiniOdierni }