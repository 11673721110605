import { Dialog } from 'vant';

export default function listen_for_app_update() {
	let refreshing = false;

	// listen for service worker update
	// the argument once to true allows the listener to be called only once AND removes the listener once invoked
	document.addEventListener('swUpdated', sw_update_available, { once: true });

	// on 'skip waiting' ricarica pagina
	navigator.serviceWorker.addEventListener('controllerchange', () => {
		console.log('sw controllerchange');
		// Prevent multiple refreshes
		if (refreshing) return;
		refreshing = true;
		// Here the actual reload of the page occurs
		console.log('window reload');
		window.location.reload()
	});
}

function sw_update_available( event: CustomEventInit ) {
	console.log('evento triggerato: ', event.detail);
	Dialog.alert({
		title: 'Aggiornamento disponibile',
		message: "Conferma per aggiornare l'app all'ultima versione",
		confirmButtonText: 'Conferma'
	}).then(() => aggiornaApp(event.detail));
}

const aggiornaApp = ( reg: ServiceWorkerRegistration ) => {
	console.log('aggiornaApp', reg);
	// Make sure we only send a 'skip waiting' message if the SW is waiting
	if (!reg || !reg.waiting) return
	// Send message to SW to skip the waiting and activate the new SW
	reg.waiting.postMessage({ type: 'SKIP_WAITING' })
}