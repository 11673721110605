
import { defineComponent, ref } from 'vue'
import { login, getOrdiniOdierni } from "@/services/api";
import { Notify } from 'vant';

export default defineComponent({
	setup() {
		const user = ref('');
		const pwd = ref('');
		const sending = ref(false);

		const accedi = async () => {
			sending.value = true;
			console.log('accedi');
			if(! await login(user.value.trim(), pwd.value.trim())) {
				console.log('not');
				Notify({ type: 'danger', message: "Accesso non autorizzato" });
			} else {
				getOrdiniOdierni();
			}
			sending.value = false;
		}

		return {	user, pwd, accedi, sending }
	}
})
